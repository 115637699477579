import type { LoaderFunctionArgs } from 'react-router-dom';

import queryClient from '@/constant/queryClient';

import { getAuditTaskDetail, getLabelTaskDetail } from '../services/manage';

export async function manageTaskDetailLoader({ params }: LoaderFunctionArgs) {
  try {
    return await queryClient.fetchQuery({
      queryKey: ['manageLabelTaskDetail', params.id],
      queryFn: () => getLabelTaskDetail({ task_id: params.id! }),
    });
  } catch (err) {
    return null;
  }
}

export async function manageAuditTaskDetailLoader({ params, request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const flow_index = url.searchParams.get('flow_index');
  try {
    return await queryClient.fetchQuery({
      queryKey: ['manageAuditTaskDetail', params.id],
      queryFn: () => getAuditTaskDetail({ task_id: params.id!, flow_index: flow_index! }),
    });
  } catch (err) {
    return null;
  }
}
