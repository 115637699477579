import request from '@/api/request';
import type { IUserInfo } from '@/api/user';

import type { ETaskStatus, IPagination } from './task';

// 获取标注任务列表
export interface ILabelTaskListItem {
  task_id: string;
  title: string;
  status: ETaskStatus;
  created_time: string;
  creator: string;
  // 剩余题数
  residue_count: number;
  // 总问题数
  total_count: number;
  flow_index?: number;
}
export const getLabelTaskList = async (params: IPagination): Promise<{ list: ILabelTaskListItem[]; total: number }> => {
  return request.post('/v1/supplier/task/label/list', params);
};

// 获取标注任务详情
export interface ILabelTaskDetail {
  task_id: string;
  // 任务标题
  title: string;
  // 任务描述
  description: string;
  // 任务状态
  status: ETaskStatus;
  // 创建时间
  created_time: number;
  // 创建者
  creator: string;
  // 分配数量
  distribute_count: number;
  // 过期时间
  expire_time: number;
  // 团队列表
  teams: {
    team_id: string;
    name: string;
  }[];
  // 进度
  progress: {
    // 总数
    total: number;
    // 已丢弃
    discarded: number;
    // 剩余
    residue: number;
  };
}
export const getLabelTaskDetail = async (params: { task_id: string }): Promise<ILabelTaskDetail> => {
  return request.post('/v1/supplier/task/label/detail', params);
};

// 标注任务统计
interface ILabelTaskStatisticsParams extends IPagination {
  task_id: string;
  username?: string;
}
export interface ILabelTaskStatisticsItem {
  label_user: IUserInfo;
  // 完成数
  completed: number;
  // 未达标题数
  discarded: number;
}
export const getLabelTaskStatistics = async (
  params: ILabelTaskStatisticsParams,
): Promise<{ list: ILabelTaskStatisticsItem[]; total: number }> => {
  return request.post('/v1/supplier/task/label/record/group/user', params);
};

// 获取审核任务列表
export const getAuditTaskList = async (params: IPagination): Promise<{ list: ILabelTaskListItem[]; total: number }> => {
  return request.post('/v1/supplier/task/audit/list', params);
};

// 获取审核任务详情
export interface IAuditTaskDetail {
  task_id: string;
  title: string;
  // 任务描述
  description: string;
  // 任务状态
  status: ETaskStatus;
  // 创建时间
  created_time: number;
  // 创建者
  creator: string;
  // 任务流程
  flow_index: number;
  // 进度
  progress: {
    total: number;
    // 已通过
    approved: number;
    // 已驳回
    rejected: number;
    // 剩余
    residue: number;
  };
  // 未达标自动打回至标注
  is_data_recreate: boolean;
}
export const getAuditTaskDetail = async (params: {
  task_id: string;
  flow_index?: string;
}): Promise<IAuditTaskDetail> => {
  return request.post('/v1/supplier/task/audit/detail', params);
};

// 审核任务统计
interface IAuditTaskStatistics extends IPagination {
  task_id: string;
  username?: string;
  flow_index?: number;
}
export interface IAuditTaskStatisticsItem {
  label_user: IUserInfo;
  completed: number;
  // 未达标题数
  discarded: number;
}
export const getAuditTaskStatistics = async (
  params: IAuditTaskStatistics,
): Promise<{ list: IAuditTaskStatisticsItem[]; total: number }> => {
  return request.post('/v1/supplier/task/audit/record/group/user', params);
};
